
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const showProgrammaticallyShowModal = () => {
      console.log('modal: ', cash('#programmatically-modal').modal)
      cash('#programmatically-modal').modal('show')
    }
    const hideProgrammaticallyShowModal = () => {
      console.log('modal: ', cash('#programmatically-modal').modal)
      cash('#programmatically-modal').modal('hide')
    }
    const toggleProgrammaticallyShowModal = () => {
      console.log('modal: ', cash('#programmatically-modal').modal)
      cash('#programmatically-modal').modal('toggle')
    }

    return {
      showProgrammaticallyShowModal,
      hideProgrammaticallyShowModal,
      toggleProgrammaticallyShowModal
    }
  }
})
